import React, { useEffect, useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import PageHeader from 'components/common/PageHeader';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import FalconComponentCard from 'components/common/FalconComponentCard';
// import { reactBootstrapDocsUrl } from 'helpers/utils';
import { toast } from 'react-toastify';
import axios from 'axios';
import API_BASE_URL from 'apiConfig';
import { useNavigate } from 'react-router-dom';
import GoogleMapReact from 'google-map-react';

const FormControl = () => {
  const navigate = useNavigate();

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [mobile, setMobile] = useState('');
  const [address, setAddress] = useState('');
  const [city, setCity] = useState('');
  // const [gps, setGps] = useState('');

  const handleNameChange = event => {
    setName(event.target.value);
  };

  const handleEmailChange = event => {
    setEmail(event.target.value);
  };

  const handleMobileChange = event => {
    setMobile(event.target.value);
  };

  const handleAddressChange = event => {
    setAddress(event.target.value);
  };

  const handleCityChange = event => {
    setCity(event.target.value);
  };

  // const handleGpsChange = event => {
  //   setGps(event.target.value);
  // };

  const [selectedOption, setSelectedOption] = useState('');
  const [options, setOptions] = useState([]);

  useEffect(() => {
    const token = localStorage.getItem('token');
    try {
      axios
        .get(`${API_BASE_URL}/doctor/get-docotorspecial`, {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            Authorization: `Bearer ${token}`
          }
        })
        .then(response => {
          setOptions(response.data.doctorspecial);
        });
    } catch (error) {
      console.error('Error fetching data: ', error);
    }
  }, []);

  const handleSelectChange = event => {
    setSelectedOption(event.target.value);
  };

  const handleSubmit = async e => {
    e.preventDefault();
    const token = localStorage.getItem('token');

    try {
      const response = await axios.post(
        `${API_BASE_URL}/doctor/add-doctor`,
        {
          name: name,
          email: email,
          mobile: mobile,
          address: address,
          city: city,
          latitude: clickedLatLng,
          logitude: clickedLonLng,
          special_id: selectedOption
        },
        {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            Authorization: `Bearer ${token}`
          }
        }
      );

      if (!response) {
        toast.error('Drug not added!', {
          theme: 'colored'
        });
      } else {
        navigate('/doctor/get-doctor', { replace: true });
        toast.success('Doctor Added Succesfully!', {
          theme: 'colored'
        });
      }
    } catch (error) {
      toast.error(error, {
        theme: 'colored'
      });
    }
  };

  // eslint-disable-next-line
  const AnyReactComponent = ({ text }) => <div>{text}</div>;

  const defaultProps = {
    center: {
      lat: 6.8631,
      lng: 79.901
    },
    zoom: 11
  };

  const center = {
    lat: 6.8631,
    lng: 79.901
  };

  const [clickedLatLng, setClickedLatLng] = useState(null);
  const [clickedLonLng, setClickedLonLng] = useState(null);

  const onMapClick = e => {
    setClickedLatLng(e.lat);
    setClickedLonLng(e.lng);
  };

  return (
    <>
      <PageHeader title="Add new Doctor" className="mb-3"></PageHeader>

      <FalconComponentCard>
        {/* <FalconComponentCard.Body code={basicFormCode} language="jsx" /> */}
        <FalconComponentCard.Body>
          <Form onSubmit={handleSubmit}>
            <Form.Group className="mb-3" controlId="formGroupName">
              <Form.Label>Doctor Name</Form.Label>
              <Form.Control
                value={name}
                name="name"
                type="text"
                placeholder="Name"
                onChange={handleNameChange}
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="formGroupName">
              <Form.Label>Email</Form.Label>
              <Form.Control
                value={email}
                name="email"
                type="email"
                placeholder="Product Catalog"
                onChange={handleEmailChange}
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="formGroupGender">
              <Form.Label>Special</Form.Label>
              <Form.Select value={selectedOption} onChange={handleSelectChange}>
                <option value="">Select your Category</option>
                {options.map(option => (
                  <option key={option.id} value={option.id}>
                    {option.name}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>

            <Form.Group className="mb-3" controlId="formGroupName">
              <Form.Label>Mobile</Form.Label>
              <Form.Control
                value={mobile}
                name="mobile"
                type="number"
                placeholder="Mobile Number"
                onChange={handleMobileChange}
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="formGroupName">
              <Form.Label>Address</Form.Label>
              <Form.Control
                value={address}
                name="address"
                type="text"
                placeholder="Address"
                onChange={handleAddressChange}
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="formGroupName">
              <Form.Label>City</Form.Label>
              <Form.Control
                value={city}
                name="city"
                type="text"
                placeholder="City"
                onChange={handleCityChange}
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="formGroupName">
              <Form.Label>Google Map Location</Form.Label>
              <div style={{ height: '100vh', width: '100%' }}>
                <GoogleMapReact
                  bootstrapURLKeys={{
                    key: 'AIzaSyDvNZNx5ER9kjFCmJzcwImDaHvFELDaV8U'
                  }}
                  defaultCenter={center}
                  defaultZoom={defaultProps.zoom}
                  onClick={e => onMapClick(e)}
                >
                  <AnyReactComponent
                    lat={6.8631}
                    lng={79.901}
                    text="Add Doctor"
                  />
                  {/* {markerPosition && <Marker position={markerPosition} />} */}
                </GoogleMapReact>
              </div>
            </Form.Group>

            <Button variant="primary" type="submit">
              Submit
            </Button>
          </Form>
        </FalconComponentCard.Body>
      </FalconComponentCard>
    </>
  );
};

export default FormControl;
