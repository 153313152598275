import { React, useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import PageHeader from 'components/common/PageHeader';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import FalconComponentCard from 'components/common/FalconComponentCard';
// import { reactBootstrapDocsUrl } from 'helpers/utils';
// import { DrugCategory } from './DrugCategory';
import { toast } from 'react-toastify';
import axios from 'axios';
import API_BASE_URL from 'apiConfig';
import { useNavigate } from 'react-router-dom';

// const basicFormCode = DrugCategory;

const FormLayout = () => {
  const navigate = useNavigate();

  const [rolename, setRoleName] = useState('');

  const handleRoleNameChange = event => {
    setRoleName(event.target.value);
  };

  // useEffect(() => {
  //   const token = localStorage.getItem('token');
  //   try {
  //     const response = axios
  //       .get(`${API_BASE_URL}/user/get-user-type`, {
  //         headers: {
  //           'Content-Type': 'application/json',
  //           Accept: 'application/json',
  //           Authorization: `Bearer ${token}`
  //         }
  //       })
  //       .then(response => {
  //         setOptions(response.data.userType);
  //       });
  //   } catch (error) {
  //     console.error('Error fetching data: ', error);
  //   }
  // }, []);

  const handleSubmit = async e => {
    e.preventDefault();
    const token = localStorage.getItem('token');

    try {
      const response = await axios.post(
        `${API_BASE_URL}/user/add-user-role`,
        {
          name: rolename
        },
        {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            Authorization: `Bearer ${token}`
          }
        }
      );

      if (!response) {
        toast.error('New User Role not added!', {
          theme: 'colored'
        });
      } else {
        setRoleName('');

        navigate('/users/user-role', { replace: true });
        toast.success('New User Role Added Succesfully!', {
          theme: 'colored'
        });
      }
    } catch (error) {
      toast.error(error, {
        theme: 'colored'
      });
    }
  };

  return (
    <>
      <PageHeader title="Add new User Role" className="mb-3"></PageHeader>

      <FalconComponentCard>
        {/* <FalconComponentCard.Header title="Basic form" light={false} /> */}
        {/* <FalconComponentCard.Body  language="jsx" /> */}
        <FalconComponentCard.Body language="jsx">
          <Form onSubmit={handleSubmit}>
            <Form.Group className="mb-3" controlId="formGroupName">
              <Form.Label>User Role Name</Form.Label>
              <Form.Control
                type="text"
                value={rolename}
                name="name"
                onChange={handleRoleNameChange}
                placeholder="User Role Name"
              />
            </Form.Group>

            <Button variant="primary" type="submit">
              Submit
            </Button>
          </Form>
        </FalconComponentCard.Body>
      </FalconComponentCard>
    </>
  );
};

export default FormLayout;
