import React from 'react';
import { createRoot } from 'react-dom/client';
import { RouterProvider } from 'react-router-dom';
import AppProvider from 'providers/AppProvider';
import { router } from 'routes';
import 'helpers/initFA';
// import paths from 'routes/paths';
// import { useNavigate } from 'react-router-dom';

const container = document.getElementById('main');
const root = createRoot(container);

// const localStorageData = localStorage.getItem('token');
// const navigate = useNavigate();

// if (!localStorageData) {
// }
root.render(
  <React.StrictMode>
    <AppProvider>
      <RouterProvider router={router} />
    </AppProvider>
  </React.StrictMode>
);
