import React, { useEffect, useState, useRef } from 'react';
import API_BASE_URL from 'apiConfig';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import AdvanceTableFooter from 'components/common/advance-table/AdvanceTableFooter';
import AdvanceTableSearchBox from 'components/common/advance-table/AdvanceTableSearchBox';
import useAdvanceTable from 'hooks/useAdvanceTable';
import AdvanceTableProvider from 'providers/AdvanceTableProvider';
import FalconComponentCard from 'components/common/FalconComponentCard';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { toast } from 'react-toastify';
import axios from 'axios';
import { Button, Form } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import { BsFillTrash3Fill } from 'react-icons/bs';
import { BsFillPencilFill } from 'react-icons/bs';
import { useNavigate } from 'react-router-dom';

const DrugListTable = () => {
  const [data, setData] = useState([]);

  const navigate = useNavigate();

  const [modalShow, setModalShow] = React.useState(false);

  const [updatemodalShow, setUpdateModalShow] = React.useState(false);

  const [refreshComp, setRefreshComp] = React.useState(false);

  const [specid, setSpecId] = useState([]);

  const [updatedrugid, setUpdateDrugId] = useState([]);

  const [selectedOption, setSelectedOption] = useState('');

  const [options, setOptions] = useState([]);

  const [selectedFile, setSelectedFile] = useState(null);

  const fileInputRef = useRef();

  const handleFileChange = event => {
    setSelectedFile(event.target.files[0]);
  };

  const handleSelectChange = event => {
    setSelectedOption(event.target.value);
  };

  const [name, setName] = useState('');

  const handleNameChange = event => {
    setName(event.target.value);
  };

  const drgDelete = id => {
    setSpecId(id.original.id);
    setModalShow(true);
  };

  // const [isButtonVisible, setIsButtonVisible] = useState(true);
  // const [isDelete, setDelete] = useState(true);

  // useEffect(() => {
  //   const buttonVisible = () => {
  //     const permission = JSON.parse(localStorage.getItem('userPermission'));
  //     // alert(permission);
  //     if (permission.includes(15)) {
  //       setIsButtonVisible(true);
  //     } else {
  //       setIsButtonVisible(false);
  //     }
  //     // if (permission.includes(16)) {
  //     //   setDelete(true);
  //     // } else {
  //     //   setDelete(false);
  //     // }
  //   };
  //   buttonVisible();
  // });

  const drugDelete = () => {
    setModalShow(false);

    const token = localStorage.getItem('token');

    try {
      const response = axios.post(
        `${API_BASE_URL}/drug/delete-drug`,
        {
          id: specid
        },
        {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            Authorization: `Bearer ${token}`
          }
        }
      );

      if (!response) {
        toast.error('Drug not Deleted!', {
          theme: 'colored'
        });
      } else {
        setRefreshComp(!refreshComp);
        navigate('/drug/drug-list', { replace: true });

        setModalShow(false);
        toast.success('Drug Deleted Succesfully!', {
          theme: 'colored'
        });
        // fetchData();
      }
    } catch (error) {
      toast.error(error, {
        theme: 'colored'
      });
    }
  };

  const drgUpdate = id => {
    setUpdateDrugId(id.original.id);

    const token = localStorage.getItem('token');

    try {
      const response = axios
        .post(
          `${API_BASE_URL}/drug/get-drug-byid`,
          {
            id: id.original.id
          },
          {
            headers: {
              'Content-Type': 'application/json',
              Accept: 'application/json',
              Authorization: `Bearer ${token}`
            }
          }
        )
        .then(data => {
          if (data.data.drug && data.data.drug !== undefined) {
            setName(data.data.drug[0].name);
          }
        });

      if (!response) {
        console.log(response);
        toast.error('Doctor Special cannot update !', {
          theme: 'colored'
        });
      } else {
        setRefreshComp(!refreshComp);
      }
    } catch (error) {
      alert('id.original.id');
      toast.error(error, {
        theme: 'colored'
      });
    }
    setUpdateModalShow(true);
  };

  const updateDrug = () => {
    const token = localStorage.getItem('token');

    const formData = new FormData();
    formData.append('id', updatedrugid);
    formData.append('product_catalog', selectedFile);
    formData.append('name', name);
    formData.append('category_id', selectedOption);

    try {
      const response = axios.post(
        `${API_BASE_URL}/drug/update-drug`,
        formData,
        {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            Authorization: `Bearer ${token}`
          }
        }
      );

      if (!response) {
        toast.error('Drug cannot update !', {
          theme: 'colored'
        });
      } else {
        setRefreshComp(!refreshComp);
        navigate('/drug/drug-list', { replace: true });

        setUpdateModalShow(false);
        toast.success('Drug Updated Succesfully!', {
          theme: 'colored'
        });
        setName('');
        // fetchData();
      }
    } catch (error) {
      toast.error(error, {
        theme: 'colored'
      });
    }
  };

  const columns = [
    {
      accessorKey: 'id',
      header: 'Id',
      meta: {
        headerProps: { className: 'text-900' }
      }
    },
    {
      accessorKey: 'name',
      header: 'Name',
      meta: {
        headerProps: { className: 'text-900' }
      }
    },
    {
      accessorKey: 'categeroyName',
      header: 'Categeroy Name',
      meta: {
        headerProps: { className: 'text-900' }
      }
    },
    {
      accessorKey: 'product_catalog',
      header: 'Product Catalog',
      cell: ({ row: { original } }) => {
        const { product_catalog } = original;
        return (
          <div className="d-flex flex-wrap gap-2">
            <a href={`${API_BASE_URL}` + product_catalog}>Product catalog</a>
            {/* <BsFillPencilFill             
              /> */}
          </div>
        );
      },
      meta: {
        headerProps: { className: 'text-900' }
      }
    },
    {
      accessorKey: 'id',
      header: 'Action',
      cell: ({ row }) => {
        return (
          <div className="d-flex flex-wrap gap-2">
            {/* {isButtonVisible && ( */}
            <Button>
              <BsFillPencilFill
                onClick={() => {
                  drgUpdate(row);
                }}
              />
            </Button>
            {/* )} */}
            {/* {isButtonVisible && ( */}
            <Button variant="danger">
              <BsFillTrash3Fill
                onClick={() => {
                  drgDelete(row);
                }}
              />
            </Button>
            {/* )} */}
          </div>
        );
      },
      meta: {
        headerProps: { className: 'text-900' }
      }
    }
  ];

  useEffect(() => {
    const token = localStorage.getItem('token');
    try {
      axios
        .get(`${API_BASE_URL}/drug/get-category`, {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            Authorization: `Bearer ${token}`
          }
        })
        .then(response => {
          setOptions(response.data.category);
        });
    } catch (error) {
      console.error('Error fetching data: ', error);
    }
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      const token = localStorage.getItem('token');
      try {
        await axios
          .get(`${API_BASE_URL}/drug/get-drug`, {
            headers: {
              'Content-Type': 'application/json',
              Accept: 'application/json',
              Authorization: `Bearer ${token}`
            }
          })
          .then(data => {
            if (data.data.status && data.data.drug !== undefined) {
              setData(data.data.drug);
            }
          });
      } catch (error) {
        console.error('Error fetching data: ', error);
      }
    };

    fetchData();
  }, [refreshComp]);

  const table = useAdvanceTable({
    data,
    columns,
    sortable: true,
    pagination: true,
    perPage: 5
  });

  return (
    <>
      <Modal
        show={modalShow}
        onHide={() => setModalShow(false)}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter">
            Drug Delete
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Are you want to delete this Drug?</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={() => drugDelete()}>
            Yes
          </Button>
          <Button onClick={() => setModalShow(false)}>No</Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={updatemodalShow}
        onHide={() => setUpdateModalShow(false)}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter">
            Drug Update
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <FalconComponentCard>
            <FalconComponentCard.Body>
              <Form>
                <Form.Group className="mb-3" controlId="formGroupName">
                  <Form.Label>Drug Name</Form.Label>
                  <Form.Control
                    value={name}
                    name="name"
                    type="text"
                    placeholder="Name"
                    onChange={handleNameChange}
                  />
                </Form.Group>

                <Form.Group className="mb-3" controlId="formGroupName">
                  <Form.Label>Product Catalog</Form.Label>
                  <Form.Control
                    type="file"
                    ref={fileInputRef}
                    // value={selectedFile}
                    name="productCatalog"
                    onChange={handleFileChange}
                    placeholder="Product Catalog"
                  />
                </Form.Group>

                <Form.Group className="mb-3" controlId="formGroupGender">
                  <Form.Label>Category</Form.Label>
                  <Form.Select
                    value={selectedOption}
                    onChange={handleSelectChange}
                  >
                    <option value="">Select your Category</option>
                    {options.map(option => (
                      <option key={option.id} value={option.id}>
                        {option.name}
                      </option>
                    ))}
                  </Form.Select>
                </Form.Group>

                <Button variant="primary" onClick={() => updateDrug()}>
                  Submit
                </Button>
                <Button
                  variant="danger"
                  onClick={() => setUpdateModalShow(false)}
                >
                  No
                </Button>
              </Form>
            </FalconComponentCard.Body>
          </FalconComponentCard>
        </Modal.Body>
      </Modal>

      <AdvanceTableProvider {...table}>
        <Row className="flex-end-center mb-3">
          <Col xs="auto" sm={6} lg={4}>
            <AdvanceTableSearchBox />
          </Col>
        </Row>
        <AdvanceTable
          headerClassName="bg-200 text-nowrap align-middle"
          rowClassName="align-middle white-space-nowrap"
          tableProps={{
            bordered: true,
            striped: true,
            className: 'fs-10 mb-0 overflow-hidden'
          }}
        />
        <div className="mt-3">
          <AdvanceTableFooter rowInfo navButtons rowsPerPageSelection />
        </div>
      </AdvanceTableProvider>
    </>
  );
};

export default DrugListTable;
