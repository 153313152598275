import React from 'react';
// import { BrowserRouter, Routes, Route } from 'react-router-dom';
// import { NavLink, useLocation } from 'react-router-dom';
// import { Button } from 'react-bootstrap';
import PageHeader from 'components/common/PageHeader';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import FalconComponentCard from 'components/common/FalconComponentCard';
// import IconButton from 'components/common/IconButton';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import AdvanceTableFooter from 'components/common/advance-table/AdvanceTableFooter';
import AdvanceTableSearchBox from 'components/common/advance-table/AdvanceTableSearchBox';
// import AdvanceTablePagination from 'components/common/advance-table/AdvanceTablePagination';
import useAdvanceTable from 'hooks/useAdvanceTable';
import AdvanceTableProvider from 'providers/AdvanceTableProvider';
// import { Link } from 'react-router-dom';
// import paths from 'routes/paths';
import DoctorList from './DoctorListTable';

const AdvanceTableExamples = () => (
  <>
    <PageHeader title="Doctor List" className="mb-3"></PageHeader>

    <FalconComponentCard>
      <FalconComponentCard.Body
        scope={{
          useAdvanceTable,
          AdvanceTableProvider,
          AdvanceTable,
          AdvanceTableFooter,
          AdvanceTableSearchBox
        }}
        language="jsx"
        noInline
        noLight
      />
      <DoctorList />
    </FalconComponentCard>
  </>
);

export default AdvanceTableExamples;
