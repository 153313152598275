// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
// import { Link } from 'react-router-dom';
import { Dropdown } from 'react-bootstrap';
import team3 from 'assets/img/team/3.jpg';
import Avatar from 'components/common/Avatar';
import axios from 'axios';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import API_BASE_URL from 'apiConfig';
// import paths from 'routes/paths';

const ProfileDropdown = () => {
  const navigate = useNavigate();
  const logOut = () => {
    const token = localStorage.getItem('token');

    try {
      const response = axios.post(`${API_BASE_URL}/logout`, {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: `Bearer ${token}`
        }
      });

      if (!response) {
        console.log(response);
        toast.error('You cannot Logout!', {
          theme: 'colored'
        });
      } else {
        navigate('/login', { replace: true });
        localStorage.removeItem('token');
        toast.success('Logout Succesfully!', {
          theme: 'colored'
        });
      }
    } catch (error) {
      alert(404);
      toast.error(error, {
        theme: 'colored'
      });
    }
  };
  return (
    <Dropdown navbar={true} as="li">
      <Dropdown.Toggle bsPrefix="toggle" className="pe-0 ps-2 nav-link">
        <Avatar src={team3} />
      </Dropdown.Toggle>

      <Dropdown.Menu className="dropdown-caret dropdown-menu-card  dropdown-menu-end">
        <div className="bg-white rounded-2 py-2 dark__bg-1000">
          <Dropdown.Item onClick={logOut}>Logout</Dropdown.Item>
        </div>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default ProfileDropdown;
