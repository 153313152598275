import React, { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
// import { BrowserRouter, Routes, Route } from 'react-router-dom';
// import { NavLink, useLocation } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import PageHeader from 'components/common/PageHeader';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import FalconComponentCard from 'components/common/FalconComponentCard';
// import IconButton from 'components/common/IconButton';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import AdvanceTableFooter from 'components/common/advance-table/AdvanceTableFooter';
import AdvanceTableSearchBox from 'components/common/advance-table/AdvanceTableSearchBox';
// import AdvanceTablePagination from 'components/common/advance-table/AdvanceTablePagination';
import useAdvanceTable from 'hooks/useAdvanceTable';
import axios from 'axios';
import API_BASE_URL from 'apiConfig';
import AdvanceTableProvider from 'providers/AdvanceTableProvider';
import { toast } from 'react-toastify';
// import { Link } from 'react-router-dom';
// import paths from 'routes/paths';
import GoogleMapReact from 'google-map-react';
import { useNavigate } from 'react-router-dom';

const AdvanceTableExamples = () => {
  const navigate = useNavigate();
  const [latitude, setLatitude] = useState(null);
  const [longitude, setLongitude] = useState(null);
  // const [error, setError] = useState(null);

  const onMapClick = () => {
    const docId = localStorage.getItem('docId');
    alert(latitude);
    alert(longitude);
    alert(docId);

    try {
      const token = localStorage.getItem('token');

      const response = axios.post(
        `${API_BASE_URL}/doctor/add-doctor-visit`,
        {
          id: docId,
          latitude: latitude,
          longitude: longitude
        },
        {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            Authorization: `Bearer ${token}`
          }
        }
      );

      if (!response) {
        toast.error('Doctor Visit Marked Not Complete!', {
          theme: 'colored'
        });
      } else {
        navigate('/medi-rep/doc-list', { replace: true });
        toast.success('Doctor Visit Marked Succesfully!', {
          theme: 'colored'
        });
      }
    } catch (error) {
      console.error('Error fetching data: ', error);
    }
  };

  useEffect(() => {
    const realTimeLocation = () => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          position => {
            setLatitude(position.coords.latitude);
            setLongitude(position.coords.longitude);
          }
          // error => {
          //   setError(error.message);
          // }
        );
        console.log(latitude, longitude);
      } else {
        console.log('Geolocation is not supported by this browser.');
      }
    };
    setInterval(function () {
      realTimeLocation();
    }, 1000);
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = localStorage.getItem('token');
        const docId = localStorage.getItem('docId');
        alert(latitude);
        alert(longitude);
        alert(docId);
        await axios
          .post(
            `${API_BASE_URL}/doctor/med-rep`,
            {
              id: docId,
              latitude: latitude,
              longitude: longitude
            },
            {
              headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                Authorization: `Bearer ${token}`
              }
            }
          )
          .then(data => {
            alert(data);
            console.log(data);

            //   if (data.data.status && data.data.doctors !== undefined) {
            //     setData(data.data.doctors);
            //   }
          });
      } catch (error) {
        console.error('Error fetching data: ', error);
      }
    };

    fetchData();

    // setInterval(function(){
    //   fetchData();
    // }, 10000);
  }, []);

  // eslint-disable-next-line
  const AnyReactComponent = ({ text }) => <div>{text}</div>;

  const defaultProps = {
    center: {
      lat: 6.8631,
      lng: 79.901
    },
    zoom: 11
  };

  const center = {
    lat: 6.8631,
    lng: 79.901
  };

  return (
    <>
      <PageHeader title="Doctor List" className="mb-3"></PageHeader>

      <PageHeader className="mb-3"></PageHeader>

      <FalconComponentCard>
        <FalconComponentCard.Body
          scope={{
            useAdvanceTable,
            AdvanceTableProvider,
            AdvanceTable,
            AdvanceTableFooter,
            AdvanceTableSearchBox
          }}
          language="jsx"
          noInline
          noLight
        />

        <Form.Group className="mb-3" controlId="formGroupName">
          <Form.Label>Google Map Location</Form.Label>
          <div style={{ height: '100vh', width: '100%' }}>
            <GoogleMapReact
              bootstrapURLKeys={{
                key: 'AIzaSyDvNZNx5ER9kjFCmJzcwImDaHvFELDaV8U'
              }}
              defaultCenter={center}
              defaultZoom={defaultProps.zoom}
            >
              <AnyReactComponent lat={6.8631} lng={79.901} text="Add Doctor" />
            </GoogleMapReact>
          </div>
        </Form.Group>
        <Form.Group className="mb-3" controlId="formGroupName">
          <Button
            onClick={() => {
              onMapClick();
            }}
          >
            Confirm Doctor Visit
          </Button>
        </Form.Group>
      </FalconComponentCard>
    </>
  );
};

export default AdvanceTableExamples;
