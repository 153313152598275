import React, { useState } from 'react';
import { Form, Card, Button } from 'react-bootstrap';
import PageHeader from 'components/common/PageHeader';
import FalconCardHeader from 'components/common/FalconCardHeader';
import { toast } from 'react-toastify';
import axios from 'axios';
import API_BASE_URL from 'apiConfig';
import { useNavigate } from 'react-router-dom';

// import AccountSettings from './AccountSettings';
// import FalconComponentCard from 'components/common/FalconComponentCard';
// import TooltipBadge from 'components/common/TooltipBadge';
// import useAdvanceTable from 'hooks/useAdvanceTable';
// import AdvanceTableProvider, {
//   useAdvanceTableContext
// } from 'providers/AdvanceTableProvider';

const Settings = () => {
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    roleId: localStorage.getItem('roleId'),
    docSpecialCreate: false,
    docSpecialView: false,
    docSpecialEdit: false,
    docSpecialDelete: false,
    docCreate: false,
    docView: false,
    docEdit: false,
    docDelete: false,
    drugCategoryCreate: false,
    drugCategoryView: false,
    drugCategoryEdit: false,
    drugCategoryDelete: false,
    drugCreate: false,
    drugView: false,
    drugEdit: false,
    drugDelete: false,
    siteVisit: false,
    mediRepView: false,
    userStatus: false,
    userView: false,
    userCreate: false,
    userRoleCreate: false,
    userRoleView: false,
    userRolePermission: false
  });

  // const [datas, setDatas] = useState('');

  const handleChange = e => {
    if (e.target.type === 'checkbox') {
      setFormData({
        ...formData,
        // [e.target.name]: e.target.checked,
        [e.target.name]: e.target.value
      });
    } else {
      setFormData({
        ...formData,
        [e.target.name]: e.target.value
      });
    }
  };

  // useEffect(() => {
  //   const fetchData = async () => {
  //     const token = localStorage.getItem('token');
  //     const userTypeId = localStorage.getItem('roleId');
  //     try {
  //       const response = axios
  //         .post(`${API_BASE_URL}/user/get-userrole-permission`, {
  //           userTypeId:userTypeId
  //         },{
  //           headers: {
  //             'Content-Type': 'application/json',
  //             Accept: 'application/json',
  //             Authorization: `Bearer ${token}`
  //           }
  //         })
  //         .then(data => {
  //           // console.log(data.data.userPermission);
  //           if (data.data.status && data.data.userPermission !== undefined) {
  //             // alert(data.data.userPermission);
  //             setPermissions(data.data.userPermission);
  //           }
  //           // {permissions.map(permissions => (
  //           //   console.log((permissions.permission))
  //           // ))}
  //           // console.log(permissions);
  //           if(permissions.includes(1)){
  //             setFormData({
  //               roleId :localStorage.getItem('roleId'),
  //               docSpecialCreate : true
  //             });
  //             // handleChange();
  //           }
  //         });
  //     } catch (error) {
  //       console.error('Error fetching data: ', error);
  //     }
  //   };

  //   fetchData();
  // },);

  const handleSubmit = e => {
    e.preventDefault();
    // console.log(formData);
    const token = localStorage.getItem('token');
    try {
      const response = axios.post(
        `${API_BASE_URL}/user/add-userole-permission`,
        { formData },
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            Accept: 'application/json',
            Authorization: `Bearer ${token}`
          }
        }
      );

      if (!response) {
        toast.error('User Permission not added!', {
          theme: 'colored'
        });
      } else {
        navigate('/users/user-role', { replace: true });
        toast.success('User Permission Added Succesfully!', {
          theme: 'colored'
        });
      }
    } catch (error) {
      console.error('Error fetching data: ', error);
    }
  };

  return (
    <>
      <PageHeader title="Permission" className="mb-3"></PageHeader>
      {/* <FalconComponentCard>      */}

      <Card className="mb-8">
        <FalconCardHeader title="Add User Role Permission" />
        <Card.Body className="bg-body-tertiary">
          <Form onSubmit={handleSubmit}>
            <div className="border-dashed border-bottom my-3" />
            <h6 className="fw-bold">Doctor Special</h6>
            <div className="ps-2">
              <Form.Check
                type="checkbox"
                id="show-followers"
                label="Create"
                className="form-label-nogutter"
                name="docSpecialCreate"
                value={2}
                onChange={handleChange}
                checked={formData.docSpecialCreate}
              />
              <Form.Check
                type="checkbox"
                id="show-email"
                label="View"
                className="form-label-nogutter"
                name="docSpecialView"
                value={1}
                onChange={handleChange}
                // checked={formData.docSpecialView}
              />
              <Form.Check
                type="checkbox"
                id="show-experience"
                label="Edit"
                className="form-label-nogutter"
                name="docSpecialEdit"
                value={3}
                onChange={handleChange}
                // checked={formData.docSpecialEdit}
              />
              <Form.Check
                type="checkbox"
                id="show-experience"
                label="Delete"
                className="form-label-nogutter"
                name="docSpecialDelete"
                value={4}
                onChange={handleChange}
                // checked={formData.docSpecialDelete}
              />
            </div>

            <div className="border-dashed border-bottom my-3" />

            <h6 className="fw-bold">Doctor</h6>
            <div className="ps-2">
              <Form.Check
                type="checkbox"
                id="show-followers"
                label="Create"
                className="form-label-nogutter"
                name="docCreate"
                value={6}
                onChange={handleChange}
                // checked={formData.docCreate}
              />
              <Form.Check
                type="checkbox"
                id="show-email"
                label="View"
                className="form-label-nogutter"
                name="docView"
                value={5}
                onChange={handleChange}
                // checked={formData.docView}
              />
              <Form.Check
                type="checkbox"
                id="show-experience"
                label="Edit"
                className="form-label-nogutter"
                name="docEdit"
                value={7}
                onChange={handleChange}
                // checked={formData.docEdit}
              />
              <Form.Check
                type="checkbox"
                id="show-experience"
                label="Delete"
                className="form-label-nogutter"
                name="docDelete"
                value={8}
                onChange={handleChange}
                // checked={formData.docDelete}
              />
            </div>
            <div className="border-dashed border-bottom my-3" />

            <h6 className="fw-bold">Drug Category</h6>
            <div className="ps-2">
              <Form.Check
                type="checkbox"
                id="show-followers"
                label="Create"
                className="form-label-nogutter"
                name="drugCategoryCreate"
                value={10}
                onChange={handleChange}
                // checked={formData.drugCategoryCreate}
              />
              <Form.Check
                type="checkbox"
                id="show-email"
                label="View"
                className="form-label-nogutter"
                name="drugCategoryView"
                value={9}
                onChange={handleChange}
                // checked={formData.drugCategoryView}
              />
              <Form.Check
                type="checkbox"
                id="show-experience"
                label="Edit"
                className="form-label-nogutter"
                name="drugCategoryEdit"
                value={11}
                onChange={handleChange}
                // checked={formData.drugCategoryEdit}
              />
              <Form.Check
                type="checkbox"
                id="show-experience"
                label="Delete"
                className="form-label-nogutter"
                name="drugCategoryDelete"
                value={12}
                onChange={handleChange}
                // checked={formData.drugCategoryDelete}
              />
            </div>

            <div className="border-dashed border-bottom my-3" />

            <h6 className="fw-bold">Drug</h6>
            <div className="ps-2">
              <Form.Check
                type="checkbox"
                id="show-followers"
                label="Create"
                className="form-label-nogutter"
                name="drugCreate"
                value={14}
                onChange={handleChange}
                // checked={formData.drugCreate}
              />
              <Form.Check
                type="checkbox"
                id="show-email"
                label="View"
                className="form-label-nogutter"
                name="drugView"
                value={13}
                onChange={handleChange}
                // checked={formData.drugView}
              />
              <Form.Check
                type="checkbox"
                id="show-experience"
                label="Edit"
                className="form-label-nogutter"
                name="drugEdit"
                value={15}
                onChange={handleChange}
                // checked={formData.drugEdit}
              />
              <Form.Check
                type="checkbox"
                id="show-experience"
                label="Delete"
                className="form-label-nogutter"
                name="drugDelete"
                value={16}
                onChange={handleChange}
                // checked={formData.drugDelete}
              />
            </div>

            <div className="border-dashed border-bottom my-3" />

            <h6 className="fw-bold">Medi Rep</h6>
            <div className="ps-2">
              <Form.Check
                type="checkbox"
                id="show-followers"
                label="Site Visit"
                className="form-label-nogutter"
                name="siteVisit"
                value={17}
                onChange={handleChange}
                // checked={formData.siteVisit}
              />
              <Form.Check
                type="checkbox"
                id="show-email"
                label="View"
                className="form-label-nogutter"
                name="mediRepView"
                value={18}
                onChange={handleChange}
                // checked={formData.mediRepView}
              />
            </div>
            <div className="border-dashed border-bottom my-3" />

            <h6 className="fw-bold">User</h6>
            <div className="ps-2">
              <Form.Check
                type="checkbox"
                id="show-followers"
                label="Create"
                className="form-label-nogutter"
                name="userCreate"
                value={19}
                onChange={handleChange}
                // checked={formData.userCreate}
              />
              <Form.Check
                type="checkbox"
                id="show-email"
                label="View"
                className="form-label-nogutter"
                name="userView"
                value={20}
                onChange={handleChange}
                // checked={formData.userView}
              />
              <Form.Check
                type="checkbox"
                id="show-experience"
                label="Status"
                className="form-label-nogutter"
                name="userStatus"
                value={21}
                onChange={handleChange}
                // checked={formData.userStatus}
              />
            </div>

            <div className="border-dashed border-bottom my-3" />

            <h6 className="fw-bold">User Role</h6>
            <div className="ps-2">
              <Form.Check
                type="checkbox"
                id="show-followers"
                label="Create"
                className="form-label-nogutter"
                name="userRoleCreate"
                value={25}
                onChange={handleChange}
                // checked={formData.userRoleCreate}
              />
              <Form.Check
                type="checkbox"
                id="show-email"
                label="View"
                className="form-label-nogutter"
                name="userRoleView"
                value={23}
                onChange={handleChange}
                // checked={formData.userRoleView}
              />
              <Form.Check
                type="checkbox"
                id="show-experience"
                label="Permissions"
                className="form-label-nogutter"
                name="userRolePermission"
                value={24}
                onChange={handleChange}
                // checked={formData.userRolePermission}
              />
            </div>
            <div className="border-dashed border-bottom my-3" />

            <Button variant="primary" type="submit">
              Submit
            </Button>
          </Form>
        </Card.Body>
      </Card>
      {/* </FalconComponentCard> */}
    </>
  );
};

export default Settings;
