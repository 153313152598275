import React, { useEffect, useState } from 'react';
import API_BASE_URL from 'apiConfig';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import AdvanceTableFooter from 'components/common/advance-table/AdvanceTableFooter';
import AdvanceTableSearchBox from 'components/common/advance-table/AdvanceTableSearchBox';
import useAdvanceTable from 'hooks/useAdvanceTable';
import AdvanceTableProvider from 'providers/AdvanceTableProvider';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
// import FalconComponentCard from 'components/common/FalconComponentCard';
import axios from 'axios';
import Modal from 'react-bootstrap/Modal';
import { Button } from 'react-bootstrap';
// import { BsFillTrash3Fill } from 'react-icons/bs';
// import { BsFillPencilFill } from 'react-icons/bs';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
// import GoogleMapReact from 'google-map-react';

const UserList = () => {
  const navigate = useNavigate();

  const [modalShow, setModalShow] = React.useState(false);
  const [data, setData] = useState([]);
  // eslint-disable-next-line
  const [specid, setSpecId] = useState([]);
  // const [updatedoc, setUpdateDoc] = useState([]);
  const [refreshComp, setRefreshComp] = React.useState(false);
  // const [updatemodalShow, setUpdateModalShow] = React.useState(false);

  // const [name, setName] = useState('');
  // const [email, setEmail] = useState('');
  // const [mobile, setMobile] = useState('');
  // const [address, setAddress] = useState('');
  // const [city, setCity] = useState('');
  // const [gps, setGps] = useState('');
  // const [selectedOption, setSelectedOption] = useState('');
  // const [options, setOptions] = useState([]);
  // const [clickedLatLng, setClickedLatLng] = useState(null);
  // const [clickedLonLng, setClickedLonLng] = useState(null);

  // const handleEmailChange = event => {
  //   setEmail(event.target.value);
  // };

  // const handleSelectChange = event => {
  //   setSelectedOption(event.target.value);
  // };

  // const handleMobileChange = event => {
  //   setMobile(event.target.value);
  // };

  // const handleAddressChange = event => {
  //   setAddress(event.target.value);
  // };

  // const handleCityChange = event => {
  //   setCity(event.target.value);
  // };

  // const handleNameChange = event => {
  //   setName(event.target.value);
  // };

  const userPermission = id => {
    alert(id.original.id);
    localStorage.setItem('userId', id.original.id);
    navigate('/users/add-permission', { replace: true });
  };

  const statusUpdate = () => {
    const token = localStorage.getItem('token');

    try {
      const response = axios.post(
        `${API_BASE_URL}/user/delete-user`,
        {
          id: specid
        },
        {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            Authorization: `Bearer ${token}`
          }
        }
      );

      if (!response) {
        toast.error('User Status Not updated!', {
          theme: 'colored'
        });
      } else {
        setRefreshComp(!refreshComp);
        navigate('/users/user-list', { replace: true });

        setModalShow(false);
        toast.success('User Status Updated Succesfully!', {
          theme: 'colored'
        });
        // fetchData();
      }
    } catch (error) {
      toast.error(error, {
        theme: 'colored'
      });
    }
  };

  const columns = [
    {
      accessorKey: 'id',
      header: 'Id',
      meta: {
        headerProps: { className: 'text-900' }
      }
    },
    {
      accessorKey: 'f_name',
      header: 'First Name',
      meta: {
        headerProps: { className: 'text-900' }
      }
    },
    {
      accessorKey: 'l_name',
      header: 'Last Name',
      meta: {
        headerProps: { className: 'text-900' }
      }
    },
    {
      accessorKey: 'email',
      header: 'Email',
      meta: {
        headerProps: { className: 'text-900' }
      }
    },
    {
      accessorKey: 'id',
      header: 'Permission',
      cell: ({ row }) => {
        return (
          <>
            <div className="d-flex flex-wrap gap-2">
              <Button
                onClick={() => {
                  userPermission(row);
                }}
              >
                Add Permission
              </Button>
            </div>
          </>
        );
      },
      meta: {
        headerProps: { className: 'text-900' }
      }
    }
  ];

  useEffect(() => {
    const fetchData = async () => {
      const token = localStorage.getItem('token');
      try {
        await axios
          .get(`${API_BASE_URL}/user/all-users`, {
            headers: {
              'Content-Type': 'application/json',
              Accept: 'application/json',
              Authorization: `Bearer ${token}`
            }
          })
          .then(data => {
            // console.log(data.data.doctorspecial);
            if (data.data.status && data.data.allUsers !== undefined) {
              setData(data.data.allUsers);
            }
          });
      } catch (error) {
        console.error('Error fetching data: ', error);
      }
    };

    fetchData();
  }, [refreshComp]);

  const table = useAdvanceTable({
    data,
    columns,
    sortable: true,
    pagination: true,
    perPage: 5
  });

  useEffect(() => {
    const token = localStorage.getItem('token');
    try {
      axios.get(`${API_BASE_URL}/doctor/get-docotorspecial`, {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: `Bearer ${token}`
        }
      });
      // .then(response => {
      //   setOptions(response.data.doctorspecial);
      // });
    } catch (error) {
      console.error('Error fetching data: ', error);
    }
  }, []);

  return (
    <>
      <Modal
        show={modalShow}
        onHide={() => setModalShow(false)}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter">
            User Status Update
          </Modal.Title>
          {/* <CloseButton
            className="btn btn-circle btn-sm transition-base p-0"
            onClick={() => setModalShow(false)}
          /> */}
        </Modal.Header>
        <Modal.Body>
          {/* <h4>Doctor Special Delete</h4> */}
          <p>Are you want to update user status?</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={() => statusUpdate()}>
            Yes
          </Button>
          <Button onClick={() => setModalShow(false)}>No</Button>
        </Modal.Footer>
      </Modal>

      <AdvanceTableProvider {...table}>
        <Row className="flex-end-center mb-3">
          <Col xs="auto" sm={6} lg={4}>
            <AdvanceTableSearchBox />
          </Col>
        </Row>
        <AdvanceTable
          headerClassName="bg-200 text-nowrap align-middle"
          rowClassName="align-middle white-space-nowrap"
          tableProps={{
            bordered: true,
            striped: true,
            className: 'fs-10 mb-0 overflow-hidden'
          }}
        />
        <div className="mt-3">
          <AdvanceTableFooter rowInfo navButtons rowsPerPageSelection />
        </div>
      </AdvanceTableProvider>
    </>
  );
};

export default UserList;
