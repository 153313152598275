import React, { useEffect, useState } from 'react';
import API_BASE_URL from 'apiConfig';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import AdvanceTableFooter from 'components/common/advance-table/AdvanceTableFooter';
import AdvanceTableSearchBox from 'components/common/advance-table/AdvanceTableSearchBox';
import useAdvanceTable from 'hooks/useAdvanceTable';
import AdvanceTableProvider from 'providers/AdvanceTableProvider';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
// import FalconComponentCard from 'components/common/FalconComponentCard';
import axios from 'axios';
// import Modal from 'react-bootstrap/Modal';
import { Button } from 'react-bootstrap';
// import { BsFillTrash3Fill } from 'react-icons/bs';
import { BsFillPencilFill } from 'react-icons/bs';
import { useNavigate } from 'react-router-dom';
// import { toast } from 'react-toastify';
// import GoogleMapReact from 'google-map-react';

const DoctorList = () => {
  const navigate = useNavigate();

  const [data, setData] = useState([]);

  // eslint-disable-next-line
  const [refreshComp, setRefreshComp] = React.useState(false);
  // setRefreshComp(false);

  const docUpdate = id => {
    localStorage.setItem('docId', id.original.id);
    navigate('/medi-rep/doctor-sitevisit', { replace: true });
  };

  const columns = [
    {
      accessorKey: 'id',
      header: 'Id',
      meta: {
        headerProps: { className: 'text-900' }
      }
    },
    {
      accessorKey: 'name',
      header: 'Docto Name',
      meta: {
        headerProps: { className: 'text-900' }
      }
    },

    {
      accessorKey: 'id',
      header: 'Site Visit',
      cell: ({ row }) => {
        return (
          <>
            <div className="d-flex flex-wrap gap-2">
              <Button>
                <BsFillPencilFill
                  onClick={() => {
                    docUpdate(row);
                  }}
                />
              </Button>
            </div>
          </>
        );
      },
      meta: {
        headerProps: { className: 'text-900' }
      }
    }
  ];

  useEffect(() => {
    const fetchData = async () => {
      const token = localStorage.getItem('token');
      try {
        await axios
          .get(`${API_BASE_URL}/doctor/get-doctor`, {
            headers: {
              'Content-Type': 'application/json',
              Accept: 'application/json',
              Authorization: `Bearer ${token}`
            }
          })
          .then(data => {
            // console.log(data.data.doctorspecial);
            if (data.data.status && data.data.doctors !== undefined) {
              setData(data.data.doctors);
            }
          });
      } catch (error) {
        console.error('Error fetching data: ', error);
      }
    };

    fetchData();
  }, [refreshComp]);

  const table = useAdvanceTable({
    data,
    columns,
    sortable: true,
    pagination: true,
    perPage: 5
  });

  return (
    <>
      <AdvanceTableProvider {...table}>
        <Row className="flex-end-center mb-3">
          <Col xs="auto" sm={6} lg={4}>
            <AdvanceTableSearchBox />
          </Col>
        </Row>
        <AdvanceTable
          headerClassName="bg-200 text-nowrap align-middle"
          rowClassName="align-middle white-space-nowrap"
          tableProps={{
            bordered: true,
            striped: true,
            className: 'fs-10 mb-0 overflow-hidden'
          }}
        />
        <div className="mt-3">
          <AdvanceTableFooter rowInfo navButtons rowsPerPageSelection />
        </div>
      </AdvanceTableProvider>
    </>
  );
};

export default DoctorList;
