import React, { useEffect, useState } from 'react';
import API_BASE_URL from 'apiConfig';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import AdvanceTableFooter from 'components/common/advance-table/AdvanceTableFooter';
import AdvanceTableSearchBox from 'components/common/advance-table/AdvanceTableSearchBox';
import useAdvanceTable from 'hooks/useAdvanceTable';
import AdvanceTableProvider from 'providers/AdvanceTableProvider';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import FalconComponentCard from 'components/common/FalconComponentCard';
import axios from 'axios';
import Modal from 'react-bootstrap/Modal';
import { Button, Form } from 'react-bootstrap';
import { BsFillTrash3Fill } from 'react-icons/bs';
import { BsFillPencilFill } from 'react-icons/bs';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import GoogleMapReact from 'google-map-react';
import { BsViewList } from 'react-icons/bs';

const DoctorList = () => {
  const navigate = useNavigate();

  const [modalShow, setModalShow] = React.useState(false);
  const [data, setData] = useState([]);
  // const [permission, setPermission] = useState('');
  const [specid, setSpecId] = useState([]);
  const [updatedoc, setUpdateDoc] = useState([]);
  const [refreshComp, setRefreshComp] = React.useState(false);
  const [updatemodalShow, setUpdateModalShow] = React.useState(false);
  const [viewModalShow, setViewModalShow] = React.useState(false);

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [mobile, setMobile] = useState('');
  const [address, setAddress] = useState('');
  const [city, setCity] = useState('');
  // const [gps, setGps] = useState('');
  const [selectedOption, setSelectedOption] = useState('');
  const [options, setOptions] = useState([]);
  const [clickedLatLng, setClickedLatLng] = useState(null);
  const [clickedLonLng, setClickedLonLng] = useState(null);

  const handleEmailChange = event => {
    setEmail(event.target.value);
  };

  const handleSelectChange = event => {
    setSelectedOption(event.target.value);
  };

  const handleMobileChange = event => {
    setMobile(event.target.value);
  };

  const handleAddressChange = event => {
    setAddress(event.target.value);
  };

  const handleCityChange = event => {
    setCity(event.target.value);
  };

  const handleNameChange = event => {
    setName(event.target.value);
  };

  const docSpecialDelete = id => {
    setSpecId(id.original.id);
    alert(id.original.id);
    setModalShow(true);
  };

  // eslint-disable-next-line
  const AnyReactComponent = ({ text }) => <div>{text}</div>;

  const defaultProps = {
    center: {
      lat: 6.8631,
      lng: 79.901
    },
    zoom: 11
  };

  const center = {
    lat: 6.8631,
    lng: 79.901
  };

  const onMapClick = e => {
    setClickedLatLng(e.lat);
    setClickedLonLng(e.lng);
  };

  const docDelete = () => {
    // alert(specid);
    const token = localStorage.getItem('token');

    try {
      const response = axios.post(
        `${API_BASE_URL}/doctor/delete-doctor`,
        {
          id: specid
        },
        {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            Authorization: `Bearer ${token}`
          }
        }
      );

      if (!response) {
        toast.error('Doctor not Deleted!', {
          theme: 'colored'
        });
      } else {
        setRefreshComp(!refreshComp);
        // navigate('/doctor/get-doctor-special', { replace: true });

        setModalShow(false);
        toast.success('Doctor Deleted Succesfully!', {
          theme: 'colored'
        });
        // fetchData();
      }
    } catch (error) {
      toast.error(error, {
        theme: 'colored'
      });
    }
  };

  const docView = id => {
    const token = localStorage.getItem('token');

    try {
      const response = axios
        .post(
          `${API_BASE_URL}/doctor/get-doctor-byid`,
          {
            id: id.original.id
          },
          {
            headers: {
              'Content-Type': 'application/json',
              Accept: 'application/json',
              Authorization: `Bearer ${token}`
            }
          }
        )
        .then(data => {
          if (data.data.doctor && data.data.doctor !== undefined) {
            setName(data.data.doctor[0].name);
            setEmail(data.data.doctor[0].email);
            setMobile(data.data.doctor[0].mobile);
            setAddress(data.data.doctor[0].address);
            setCity(data.data.doctor[0].city);
            setClickedLatLng(data.data.doctor[0].latitude);
            setClickedLonLng(data.data.doctor[0].logitude);
          }
        });

      if (!response) {
        console.log(response);
        toast.error('Doctor Special cannot update !', {
          theme: 'colored'
        });
      } else {
        setRefreshComp(!refreshComp);
      }
    } catch (error) {
      alert('id.original.id');
      toast.error(error, {
        theme: 'colored'
      });
    }

    setViewModalShow(true);
  };

  const docUpdate = id => {
    // alert(id.original.id);
    setUpdateDoc(id.original.id);
    const token = localStorage.getItem('token');

    try {
      const response = axios
        .post(
          `${API_BASE_URL}/doctor/get-doctor-byid`,
          {
            id: id.original.id
          },
          {
            headers: {
              'Content-Type': 'application/json',
              Accept: 'application/json',
              Authorization: `Bearer ${token}`
            }
          }
        )
        .then(data => {
          if (data.data.doctor && data.data.doctor !== undefined) {
            setName(data.data.doctor[0].name);
            setEmail(data.data.doctor[0].email);
            setMobile(data.data.doctor[0].mobile);
            setAddress(data.data.doctor[0].address);
            setCity(data.data.doctor[0].city);
            setClickedLatLng(data.data.doctor[0].latitude);
            setClickedLonLng(data.data.doctor[0].logitude);
          }
        });

      if (!response) {
        console.log(response);
        toast.error('Doctor Special cannot update !', {
          theme: 'colored'
        });
      } else {
        setRefreshComp(!refreshComp);
      }
    } catch (error) {
      alert('id.original.id');
      toast.error(error, {
        theme: 'colored'
      });
    }

    setUpdateModalShow(true);
  };

  const updateDoctor = () => {
    const token = localStorage.getItem('token');
    // alert(updatedoc);

    try {
      const response = axios.post(
        `${API_BASE_URL}/doctor/update-doctor`,
        {
          id: updatedoc,
          name: name,
          email: email,
          mobile: mobile,
          address: address,
          city: city,
          latitude: clickedLatLng,
          logitude: clickedLonLng,
          special_id: selectedOption
        },
        {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            Authorization: `Bearer ${token}`
          }
        }
      );

      if (!response) {
        toast.error('Doctor not updated!', {
          theme: 'colored'
        });
      } else {
        setUpdateModalShow(false);
        navigate('/doctor/get-doctor', { replace: true });
        toast.success('Doctor Updated Succesfully!', {
          theme: 'colored'
        });
      }
    } catch (error) {
      toast.error(error, {
        theme: 'colored'
      });
    }
  };

  // const [isButtonVisible, setIsButtonVisible] = useState(true);
  // const [isDelete, setDelete] = useState(true);

  // useEffect(() => {
  //   const buttonVisible = () => {
  //     const permission = JSON.parse(localStorage.getItem('userPermission'));
  //     // alert(permission);
  //     if (permission.includes(7)) {
  //       setIsButtonVisible(true);
  //     } else {
  //       setIsButtonVisible(false);
  //     }
  //     if (permission.includes(8)) {
  //       setDelete(true);
  //     } else {
  //       setDelete(false);
  //     }
  //   };
  //   buttonVisible();
  // });

  const columns = [
    {
      accessorKey: 'id',
      header: 'Id',
      meta: {
        headerProps: { className: 'text-900' }
      }
    },
    {
      accessorKey: 'name',
      header: 'Name',
      meta: {
        headerProps: { className: 'text-900' }
      }
    },
    {
      accessorKey: 'email',
      header: 'Email',
      meta: {
        headerProps: { className: 'text-900' }
      }
    },
    {
      accessorKey: 'mobile',
      header: 'Mobile',
      meta: {
        headerProps: { className: 'text-900' }
      }
    },
    {
      accessorKey: 'address',
      header: 'Address',
      meta: {
        headerProps: { className: 'text-900' }
      }
    },
    {
      accessorKey: 'city',
      header: 'City',
      meta: {
        headerProps: { className: 'text-900' }
      }
    },
    {
      accessorKey: 'logitude',
      header: 'Longitude',
      meta: {
        headerProps: { className: 'text-900' }
      }
    },
    {
      accessorKey: 'latitude',
      header: 'Latitude',
      meta: {
        headerProps: { className: 'text-900' }
      }
    },
    {
      accessorKey: 'id',
      header: 'Action',
      cell: ({ row }) => {
        return (
          <>
            <div className="d-flex flex-wrap gap-2">
              <Button>
                <BsViewList
                  onClick={() => {
                    docView(row);
                  }}
                />
              </Button>
              {/* {isButtonVisible && ( */}
              <Button>
                <BsFillPencilFill
                  onClick={() => {
                    docUpdate(row);
                  }}
                />
              </Button>
              {/* )} */}
              {/* {isDelete && ( */}
              <Button variant="danger">
                <BsFillTrash3Fill
                  onClick={() => {
                    docSpecialDelete(row);
                  }}
                />
              </Button>
              {/* )} */}
            </div>
          </>
        );
      },
      meta: {
        headerProps: { className: 'text-900' }
      }
    }
  ];

  useEffect(() => {
    const fetchData = async () => {
      const token = localStorage.getItem('token');
      try {
        await axios
          .get(`${API_BASE_URL}/doctor/get-doctor`, {
            headers: {
              'Content-Type': 'application/json',
              Accept: 'application/json',
              Authorization: `Bearer ${token}`
            }
          })
          .then(data => {
            // console.log(data.data.doctorspecial);
            if (data.data.status && data.data.doctors !== undefined) {
              setData(data.data.doctors);

              // const permission = JSON.parse(
              //   localStorage.getItem('userPermission')
              // );
              // setPermission(data1);
              // alert(permission);
            }
          });
      } catch (error) {
        console.error('Error fetching data: ', error);
      }
    };

    fetchData();
  }, [refreshComp]);

  const table = useAdvanceTable({
    data,
    columns,
    sortable: true,
    pagination: true,
    perPage: 5
  });

  useEffect(() => {
    const token = localStorage.getItem('token');
    try {
      axios
        .get(`${API_BASE_URL}/doctor/get-docotorspecial`, {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            Authorization: `Bearer ${token}`
          }
        })
        .then(response => {
          setOptions(response.data.doctorspecial);
        });
    } catch (error) {
      console.error('Error fetching data: ', error);
    }
  }, []);

  return (
    <>
      <Modal
        show={modalShow}
        onHide={() => setModalShow(false)}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter">
            Doctor Special Delete
          </Modal.Title>
          {/* <CloseButton
            className="btn btn-circle btn-sm transition-base p-0"
            onClick={() => setModalShow(false)}
          /> */}
        </Modal.Header>
        <Modal.Body>
          {/* <h4>Doctor Special Delete</h4> */}
          <p>Are you want to delete this doctor Special?</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={() => docDelete()}>
            Yes
          </Button>
          <Button onClick={() => setModalShow(false)}>No</Button>
        </Modal.Footer>
      </Modal>

      {/* Update Doctor  */}

      <Modal
        show={updatemodalShow}
        onHide={() => setUpdateModalShow(false)}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter">
            Doctor Update
          </Modal.Title>
          {/* <CloseButton
            className="btn btn-circle btn-sm transition-base p-0"
            onClick={() => setModalShow(false)}
          /> */}
        </Modal.Header>
        <Modal.Body>
          <FalconComponentCard>
            <FalconComponentCard.Body>
              <Form>
                <Form.Group className="mb-3" controlId="formGroupName">
                  <Form.Label>Doctor Name</Form.Label>
                  <Form.Control
                    value={name}
                    name="name"
                    type="text"
                    placeholder="Name"
                    onChange={handleNameChange}
                  />
                </Form.Group>

                <Form.Group className="mb-3" controlId="formGroupName">
                  <Form.Label>Email</Form.Label>
                  <Form.Control
                    value={email}
                    name="email"
                    type="email"
                    placeholder="Product Catalog"
                    onChange={handleEmailChange}
                  />
                </Form.Group>

                <Form.Group className="mb-3" controlId="formGroupGender">
                  <Form.Label>Special</Form.Label>
                  <Form.Select
                    value={selectedOption}
                    onChange={handleSelectChange}
                  >
                    <option value="">Select your Category</option>
                    {options.map(option => (
                      <option key={option.id} value={option.id}>
                        {option.name}
                      </option>
                    ))}
                  </Form.Select>
                </Form.Group>

                <Form.Group className="mb-3" controlId="formGroupName">
                  <Form.Label>Mobile</Form.Label>
                  <Form.Control
                    value={mobile}
                    name="mobile"
                    type="number"
                    placeholder="Mobile Number"
                    onChange={handleMobileChange}
                  />
                </Form.Group>

                <Form.Group className="mb-3" controlId="formGroupName">
                  <Form.Label>Address</Form.Label>
                  <Form.Control
                    value={address}
                    name="address"
                    type="text"
                    placeholder="Address"
                    onChange={handleAddressChange}
                  />
                </Form.Group>

                <Form.Group className="mb-3" controlId="formGroupName">
                  <Form.Label>City</Form.Label>
                  <Form.Control
                    value={city}
                    name="city"
                    type="text"
                    placeholder="City"
                    onChange={handleCityChange}
                  />
                </Form.Group>

                <Form.Group className="mb-3" controlId="formGroupName">
                  <Form.Label>Google Map Location</Form.Label>
                  <div style={{ height: '100vh', width: '100%' }}>
                    <GoogleMapReact
                      bootstrapURLKeys={{
                        key: 'AIzaSyDvNZNx5ER9kjFCmJzcwImDaHvFELDaV8U'
                      }}
                      defaultCenter={center}
                      defaultZoom={defaultProps.zoom}
                      onClick={e => onMapClick(e)}
                    >
                      <AnyReactComponent
                        lat={6.8631}
                        lng={79.901}
                        text="Add Doctor"
                      />
                      {/* {markerPosition && <Marker position={markerPosition} />} */}
                    </GoogleMapReact>
                  </div>
                </Form.Group>

                <Button variant="primary" onClick={() => updateDoctor()}>
                  Submit
                </Button>
                <Button
                  variant="danger"
                  onClick={() => setUpdateModalShow(false)}
                >
                  No
                </Button>
              </Form>
            </FalconComponentCard.Body>
          </FalconComponentCard>
        </Modal.Body>
      </Modal>

      {/* View Doctor */}
      <Modal
        show={viewModalShow}
        onHide={() => setViewModalShow(false)}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter">
            Doctor View
          </Modal.Title>
          {/* <CloseButton
            className="btn btn-circle btn-sm transition-base p-0"
            onClick={() => setModalShow(false)}
          /> */}
        </Modal.Header>
        <Modal.Body>
          <FalconComponentCard>
            <FalconComponentCard.Body>
              <Form.Group className="mb-3" controlId="formGroupName">
                <Form.Label>Doctor Name</Form.Label>
                <Form.Control
                  readOnly
                  value={name}
                  name="name"
                  type="text"
                  placeholder="Name"
                  onChange={handleNameChange}
                />
              </Form.Group>

              <Form.Group className="mb-3" controlId="formGroupName">
                <Form.Label>Email</Form.Label>
                <Form.Control
                  readOnly
                  value={email}
                  name="email"
                  type="email"
                  placeholder="Product Catalog"
                  onChange={handleEmailChange}
                />
              </Form.Group>

              <Form.Group className="mb-3" controlId="formGroupGender">
                <Form.Label>Special</Form.Label>
                <Form.Select
                  value={selectedOption}
                  onChange={handleSelectChange}
                >
                  <option value="">Select your Category</option>
                  {options.map(option => (
                    <option key={option.id} value={option.id}>
                      {option.name}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>

              <Form.Group className="mb-3" controlId="formGroupName">
                <Form.Label>Mobile</Form.Label>
                <Form.Control
                  readOnly
                  value={mobile}
                  name="mobile"
                  type="number"
                  placeholder="Mobile Number"
                  onChange={handleMobileChange}
                />
              </Form.Group>

              <Form.Group className="mb-3" controlId="formGroupName">
                <Form.Label>Address</Form.Label>
                <Form.Control
                  readOnly
                  value={address}
                  name="address"
                  type="text"
                  placeholder="Address"
                  onChange={handleAddressChange}
                />
              </Form.Group>

              <Form.Group className="mb-3" controlId="formGroupName">
                <Form.Label>City</Form.Label>
                <Form.Control
                  readOnly
                  value={city}
                  name="city"
                  type="text"
                  placeholder="City"
                  onChange={handleCityChange}
                />
              </Form.Group>

              <Form.Group className="mb-3" controlId="formGroupName">
                <Form.Label>Longitute</Form.Label>
                <Form.Control
                  readOnly
                  value={clickedLonLng}
                  name="logitute"
                  type="text"
                  placeholder="City"
                />
              </Form.Group>

              <Form.Group className="mb-3" controlId="formGroupName">
                <Form.Label>Latitude</Form.Label>
                <Form.Control
                  readOnly
                  value={clickedLatLng}
                  name="city"
                  type="text"
                  placeholder="City"
                />
              </Form.Group>

              <Button variant="danger" onClick={() => setViewModalShow(false)}>
                Close
              </Button>
            </FalconComponentCard.Body>
          </FalconComponentCard>
        </Modal.Body>
      </Modal>

      <AdvanceTableProvider {...table}>
        <Row className="flex-end-center mb-3">
          <Col xs="auto" sm={6} lg={4}>
            <AdvanceTableSearchBox />
          </Col>
        </Row>
        <AdvanceTable
          headerClassName="bg-200 text-nowrap align-middle"
          rowClassName="align-middle white-space-nowrap"
          tableProps={{
            bordered: true,
            striped: true,
            className: 'fs-10 mb-0 overflow-hidden'
          }}
        />
        <div className="mt-3">
          <AdvanceTableFooter rowInfo navButtons rowsPerPageSelection />
        </div>
      </AdvanceTableProvider>
    </>
  );
};

export default DoctorList;
