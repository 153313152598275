import React, { useEffect, useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import PageHeader from 'components/common/PageHeader';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import FalconComponentCard from 'components/common/FalconComponentCard';
// import { reactBootstrapDocsUrl } from 'helpers/utils';
// import { DrugCategory } from './DrugCategory';
import { toast } from 'react-toastify';
import axios from 'axios';
import API_BASE_URL from 'apiConfig';
import { useNavigate } from 'react-router-dom';

// const basicFormCode = DrugCategory;

const FormLayout = () => {
  const navigate = useNavigate();

  const [firstname, setFirstName] = useState('');
  const [lastname, setLastName] = useState('');
  const [mobile, setMobile] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [selectedOption, setSelectedOption] = useState('');
  const [options, setOptions] = useState([]);

  const handleFirstNameChange = event => {
    setFirstName(event.target.value);
  };

  const handleLastNameChange = event => {
    setLastName(event.target.value);
  };

  const handlemobileChange = event => {
    setMobile(event.target.value);
  };

  const handleEmailChange = event => {
    setEmail(event.target.value);
  };

  const handlePasswordChange = event => {
    setPassword(event.target.value);
  };

  const handleSelectChange = event => {
    setSelectedOption(event.target.value);
  };

  useEffect(() => {
    const token = localStorage.getItem('token');
    try {
      axios
        .get(`${API_BASE_URL}/user/get-user-type`, {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            Authorization: `Bearer ${token}`
          }
        })
        .then(response => {
          setOptions(response.data.userType);
        });
    } catch (error) {
      console.error('Error fetching data: ', error);
    }
  }, []);

  const handleSubmit = async e => {
    e.preventDefault();
    const token = localStorage.getItem('token');

    try {
      const response = await axios.post(
        `${API_BASE_URL}/register`,
        {
          f_name: firstname,
          l_name: lastname,
          user_type: selectedOption,
          mobile: mobile,
          email: email,
          password: password
        },
        {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            Authorization: `Bearer ${token}`
          }
        }
      );

      if (!response) {
        toast.error('New User not added!', {
          theme: 'colored'
        });
      } else {
        setFirstName('');
        setLastName('');
        setMobile('');
        setEmail('');
        setPassword('');

        navigate('/users/user-list', { replace: true });
        toast.success('New User Added Succesfully!', {
          theme: 'colored'
        });
      }
    } catch (error) {
      toast.error(error, {
        theme: 'colored'
      });
    }
  };

  return (
    <>
      <PageHeader title="Add new User" className="mb-3"></PageHeader>

      <FalconComponentCard>
        {/* <FalconComponentCard.Header title="Basic form" light={false} /> */}
        {/* <FalconComponentCard.Body  language="jsx" /> */}
        <FalconComponentCard.Body language="jsx">
          <Form onSubmit={handleSubmit}>
            <Form.Group className="mb-3" controlId="formGroupName">
              <Form.Label>User First Name</Form.Label>
              <Form.Control
                type="text"
                value={firstname}
                name="name"
                onChange={handleFirstNameChange}
                placeholder="User First Name"
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formGroupName">
              <Form.Label>User Last Name</Form.Label>
              <Form.Control
                type="text"
                value={lastname}
                name="name"
                onChange={handleLastNameChange}
                placeholder="User Last Name"
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formGroupGender">
              <Form.Label>User Type</Form.Label>
              <Form.Select value={selectedOption} onChange={handleSelectChange}>
                <option value="">Select your Category</option>
                {options.map(option => (
                  <option key={option.id} value={option.id}>
                    {option.name}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>
            <Form.Group className="mb-3" controlId="formGroupName">
              <Form.Label>Mobile</Form.Label>
              <Form.Control
                type="number"
                value={mobile}
                name="name"
                onChange={handlemobileChange}
                placeholder="User Mobile"
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formGroupName">
              <Form.Label>Email</Form.Label>
              <Form.Control
                type="email"
                value={email}
                name="name"
                onChange={handleEmailChange}
                placeholder="User Mobile"
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formGroupName">
              <Form.Label>Password</Form.Label>
              <Form.Control
                type="password"
                value={password}
                name="name"
                onChange={handlePasswordChange}
                placeholder="User Mobile"
              />
            </Form.Group>
            <Button variant="primary" type="submit">
              Submit
            </Button>
          </Form>
        </FalconComponentCard.Body>
      </FalconComponentCard>
    </>
  );
};

export default FormLayout;
