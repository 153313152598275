import React, { useEffect, useState, useRef } from 'react';
import { Button, Form } from 'react-bootstrap';
import PageHeader from 'components/common/PageHeader';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import FalconComponentCard from 'components/common/FalconComponentCard';
// import { reactBootstrapDocsUrl } from 'helpers/utils';
import { toast } from 'react-toastify';
import axios from 'axios';
import API_BASE_URL from 'apiConfig';
import { useNavigate } from 'react-router-dom';

const FormLayout = () => {
  const navigate = useNavigate();
  // const token = localStorage.getItem('token');
  const fileInputRef = useRef();

  const [selectedOption, setSelectedOption] = useState('');

  const [options, setOptions] = useState([]);

  const [name, setName] = useState('');

  const [selectedFile, setSelectedFile] = useState(null);

  const handleNameChange = event => {
    setName(event.target.value);
  };

  const handleFileChange = event => {
    setSelectedFile(event.target.files[0]);
  };

  useEffect(() => {
    const token = localStorage.getItem('token');
    try {
      axios
        .get(`${API_BASE_URL}/drug/get-category`, {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            Authorization: `Bearer ${token}`
          }
        })
        .then(response => {
          setOptions(response.data.category);
        });
    } catch (error) {
      console.error('Error fetching data: ', error);
    }
  }, []);

  const handleSelectChange = event => {
    setSelectedOption(event.target.value);
  };

  const handleSubmit = async e => {
    e.preventDefault();
    const token = localStorage.getItem('token');

    const formData = new FormData();
    formData.append('product_catalog', selectedFile);
    formData.append('name', name);
    formData.append('category_id', selectedOption);

    try {
      const response = await axios.post(
        `${API_BASE_URL}/drug/add-drug`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            Accept: 'application/json',
            Authorization: `Bearer ${token}`
          }
        }
      );

      if (!response) {
        toast.error('Drug category not added!', {
          theme: 'colored'
        });
      } else {
        setName('');
        navigate('/drug/drug-list', { replace: true });
        toast.success('Drug Added Succesfully!', {
          theme: 'colored'
        });
      }
    } catch (error) {
      toast.error(error, {
        theme: 'colored'
      });
    }
  };

  return (
    <>
      <PageHeader title="Add new drug" className="mb-3"></PageHeader>

      <FalconComponentCard>
        {/* <FalconComponentCard.Body code={basicFormCode} language="jsx" /> */}
        <FalconComponentCard.Body>
          <Form onSubmit={handleSubmit}>
            <Form.Group className="mb-3" controlId="formGroupName">
              <Form.Label>Drug Name</Form.Label>
              <Form.Control
                type="text"
                value={name}
                name="name"
                onChange={handleNameChange}
                placeholder="Name"
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="formGroupName">
              <Form.Label>Product Catalog</Form.Label>
              <Form.Control
                type="file"
                ref={fileInputRef}
                // value={selectedFile}
                name="productCatalog"
                onChange={handleFileChange}
                placeholder="Product Catalog"
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="formGroupGender">
              <Form.Label>Category</Form.Label>
              <Form.Select value={selectedOption} onChange={handleSelectChange}>
                <option value="">Select your Category</option>
                {options.map(option => (
                  <option key={option.id} value={option.id}>
                    {option.name}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>

            <Button variant="primary" type="submit">
              Submit
            </Button>
          </Form>
        </FalconComponentCard.Body>
      </FalconComponentCard>
    </>
  );
};

export default FormLayout;
