import React, { useEffect, useState } from 'react';
import API_BASE_URL from 'apiConfig';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import AdvanceTableFooter from 'components/common/advance-table/AdvanceTableFooter';
import AdvanceTableSearchBox from 'components/common/advance-table/AdvanceTableSearchBox';
import useAdvanceTable from 'hooks/useAdvanceTable';
import AdvanceTableProvider from 'providers/AdvanceTableProvider';
import FalconComponentCard from 'components/common/FalconComponentCard';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { toast } from 'react-toastify';
import axios from 'axios';
import { Button, Form } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import { BsFillTrash3Fill } from 'react-icons/bs';
import { BsFillPencilFill } from 'react-icons/bs';
import { useNavigate } from 'react-router-dom';

const DoctorSpecialList = () => {
  const navigate = useNavigate();

  const [modalShow, setModalShow] = React.useState(false);

  const [updatemodalShow, setUpdateModalShow] = React.useState(false);

  const [refreshComp, setRefreshComp] = React.useState(false);

  const [data, setData] = useState([]);

  const [specid, setSpecId] = useState([]);
  const [docspecid, setDocSpecId] = useState([]);

  const [name, setName] = useState('');

  const handleNameChange = event => {
    setName(event.target.value);
  };

  const docSpecialDelete = id => {
    setSpecId(id.original.id);
    setModalShow(true);
  };

  const specialDelete = () => {
    // alert(specid);
    const token = localStorage.getItem('token');

    try {
      const response = axios.post(
        `${API_BASE_URL}/doctor/delete-doc-special`,
        {
          id: specid
        },
        {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            Authorization: `Bearer ${token}`
          }
        }
      );

      if (!response) {
        toast.error('Doctor Special not Deleted!', {
          theme: 'colored'
        });
      } else {
        setRefreshComp(!refreshComp);
        // navigate('/doctor/get-doctor-special', { replace: true });

        setModalShow(false);
        toast.success('Doctor Special Deleted Succesfully!', {
          theme: 'colored'
        });
        // fetchData();
      }
    } catch (error) {
      toast.error(error, {
        theme: 'colored'
      });
    }
  };

  const docSpecialUpdate = id => {
    setDocSpecId(id.original.id);
    const token = localStorage.getItem('token');

    try {
      const response = axios
        .post(
          `${API_BASE_URL}/doctor/get-docspecial-byid`,
          {
            id: id.original.id
          },
          {
            headers: {
              'Content-Type': 'application/json',
              Accept: 'application/json',
              Authorization: `Bearer ${token}`
            }
          }
        )
        .then(data => {
          if (data.data.getDocSpec && data.data.getDocSpec !== undefined) {
            // console.log(data.data.getDocSpec[0].name);
            setName(data.data.getDocSpec[0].name);
            // setData(data.data.getDocSpec);
          }
        });

      if (!response) {
        console.log(response);
        toast.error('Doctor Special cannot update !', {
          theme: 'colored'
        });
      } else {
        setRefreshComp(!refreshComp);
      }
    } catch (error) {
      alert('id.original.id');
      toast.error(error, {
        theme: 'colored'
      });
    }

    setUpdateModalShow(true);
  };

  const updateDocSpecial = () => {
    setUpdateModalShow(false);
    const token = localStorage.getItem('token');

    try {
      const response = axios.post(
        `${API_BASE_URL}/doctor/update-doc-special`,
        {
          id: docspecid,
          name: name
        },
        {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            Authorization: `Bearer ${token}`
          }
        }
      );

      if (!response) {
        console.log(response);
        toast.error('Doctor Special cannot update !', {
          theme: 'colored'
        });
      } else {
        setRefreshComp(!refreshComp);
        navigate('/doctor/get-doctor-special', { replace: true });

        // setModalShow(false);
        toast.success('Doctor Special Updated Succesfully!', {
          theme: 'colored'
        });
        setName('');
        // fetchData();
      }
    } catch (error) {
      alert('id.original.id');
      toast.error(error, {
        theme: 'colored'
      });
    }
  };

  // const [isButtonVisible, setIsButtonVisible] = useState(true);
  // const [isDelete, setDelete] = useState(true);

  // useEffect(() => {
  //   const buttonVisible = () => {
  //     const permission = JSON.parse(localStorage.getItem('userPermission'));

  //     console.log(permission);
  //     if (permission.includes(3)) {
  //       setIsButtonVisible(true);
  //     } else {
  //       setIsButtonVisible(false);
  //     }

  //     if (permission.includes(4)) {
  //       setDelete(true);
  //     } else {
  //       setDelete(false);
  //     }
  //   };
  //   buttonVisible();
  // });

  const columns = [
    {
      accessorKey: 'id',
      header: 'Id',
      meta: {
        headerProps: { className: 'text-900' }
      }
    },
    {
      accessorKey: 'name',
      header: 'Name',
      meta: {
        headerProps: { className: 'text-900' }
      }
    },
    {
      accessorKey: 'specid',
      header: 'Action',
      cell: ({ row }) => {
        return (
          <div className="d-flex flex-wrap gap-2">
            {/* {isButtonVisible && ( */}
            <Button>
              <BsFillPencilFill
                onClick={() => {
                  docSpecialUpdate(row);
                }}
              />
            </Button>
            {/* )} */}
            {/* {isDelete && ( */}
            <Button variant="danger">
              <BsFillTrash3Fill
                onClick={() => {
                  docSpecialDelete(row);
                }}
              />
            </Button>
            {/* )} */}
          </div>
        );
      },
      meta: {
        headerProps: { className: 'text-900' }
      }
    }
  ];

  useEffect(() => {
    const fetchData = async () => {
      const token = localStorage.getItem('token');
      try {
        await axios
          .get(`${API_BASE_URL}/doctor/get-docotorspecial`, {
            headers: {
              'Content-Type': 'application/json',
              Accept: 'application/json',
              Authorization: `Bearer ${token}`
            }
          })
          .then(data => {
            // console.log(data.data.doctorspecial);
            if (data.data.status && data.data.doctorspecial !== undefined) {
              // console.log(data.data.doctorspecial.name);
              setData(data.data.doctorspecial);
            }
          });
      } catch (error) {
        console.error('Error fetching data: ', error);
      }
    };

    fetchData();
  }, [refreshComp]);

  const table = useAdvanceTable({
    data,
    columns,
    sortable: true,
    pagination: true,
    perPage: 5
  });

  return (
    <>
      <Modal
        show={modalShow}
        onHide={() => setModalShow(false)}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter">
            Doctor Special Delete
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Are you want to delete this doctor Special?</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={() => specialDelete()}>
            Yes
          </Button>
          <Button onClick={() => setModalShow(false)}>No</Button>
        </Modal.Footer>
      </Modal>

      {/* Update Doctor Special  */}

      <Modal
        show={updatemodalShow}
        onHide={() => setUpdateModalShow(false)}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter">
            Doctor Special Update
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <FalconComponentCard>
            <FalconComponentCard.Body>
              <Form>
                <Form.Group className="mb-3" controlId="formGroupName">
                  <Form.Label>Doctor Special Name</Form.Label>
                  <Form.Control
                    value={name}
                    name="name"
                    type="text"
                    placeholder="Name"
                    onChange={handleNameChange}
                  />
                </Form.Group>

                <Button variant="primary" onClick={() => updateDocSpecial()}>
                  Submit
                </Button>
                <Button
                  variant="danger"
                  onClick={() => setUpdateModalShow(false)}
                >
                  No
                </Button>
              </Form>
            </FalconComponentCard.Body>
          </FalconComponentCard>
        </Modal.Body>
      </Modal>

      <AdvanceTableProvider {...table}>
        <Row className="flex-end-center mb-3">
          <Col xs="auto" sm={6} lg={4}>
            <AdvanceTableSearchBox />
          </Col>
        </Row>
        <AdvanceTable
          headerClassName="bg-200 text-nowrap align-middle"
          rowClassName="align-middle white-space-nowrap"
          tableProps={{
            bordered: true,
            striped: true,
            className: 'fs-10 mb-0 overflow-hidden'
          }}
        />
        <div className="mt-3">
          <AdvanceTableFooter rowInfo navButtons rowsPerPageSelection />
        </div>
      </AdvanceTableProvider>
    </>
  );
};

export default DoctorSpecialList;
