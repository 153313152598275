import React, { useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import PageHeader from 'components/common/PageHeader';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import FalconComponentCard from 'components/common/FalconComponentCard';
// import { reactBootstrapDocsUrl } from 'helpers/utils';
import { toast } from 'react-toastify';
import axios from 'axios';
// import paths from 'routes/paths';
import API_BASE_URL from 'apiConfig';
import { useNavigate } from 'react-router-dom';

const FormLayout = () => {
  const navigate = useNavigate();

  const [fName, setFName] = useState('');
  const [lName, setLName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [mobile, setMobile] = useState('');

  const handleFNameChange = event => {
    setFName(event.target.value);
  };

  const handleLNameChange = event => {
    setLName(event.target.value);
  };

  const handleEmailChange = event => {
    setEmail(event.target.value);
  };

  const handleMobileChange = event => {
    setMobile(event.target.value);
  };

  const handlePasswordChange = event => {
    setPassword(event.target.value);
  };

  const handleSubmit = async e => {
    e.preventDefault();
    const token = localStorage.getItem('token');

    try {
      const response = await axios.post(
        `${API_BASE_URL}/register`,
        {
          f_name: fName,
          l_name: lName,
          mobile: mobile,
          email: email,
          password: password
        },
        {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            Authorization: `Bearer ${token}`
          }
        }
      );

      if (!response) {
        toast.error('New User not added!', {
          theme: 'colored'
        });
      } else {
        setFName('');
        setLName('');
        setEmail('');
        setPassword('');
        setMobile('');

        navigate('/dashboard', { replace: true });
        toast.success('New User Added Succesfully!', {
          theme: 'colored'
        });
      }
    } catch (error) {
      toast.error(error, {
        theme: 'colored'
      });
    }
  };

  return (
    <>
      <PageHeader title="Add new User" className="mb-3"></PageHeader>

      <FalconComponentCard>
        {/* <FalconComponentCard.Header title="Basic form" light={false} /> */}
        {/* <FalconComponentCard.Body  language="jsx" /> */}
        <FalconComponentCard.Body language="jsx">
          <Form onSubmit={handleSubmit}>
            <Form.Group className="mb-3" controlId="formGroupName">
              <Form.Label>First Name</Form.Label>
              <Form.Control
                type="text"
                value={fName}
                name="fName"
                onChange={handleFNameChange}
                placeholder="First Name"
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formGroupName">
              <Form.Label>Last Name</Form.Label>
              <Form.Control
                type="text"
                value={lName}
                name="lName"
                onChange={handleLNameChange}
                placeholder="Last Name"
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formGroupName">
              <Form.Label>Email</Form.Label>
              <Form.Control
                type="email"
                value={email}
                name="email"
                onChange={handleEmailChange}
                placeholder="Email"
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formGroupName">
              <Form.Label>Mobile</Form.Label>
              <Form.Control
                type="number"
                value={mobile}
                name="mobile"
                onChange={handleMobileChange}
                placeholder="Mobile"
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formGroupName">
              <Form.Label>Password</Form.Label>
              <Form.Control
                type="text"
                value={password}
                name="lName"
                onChange={handlePasswordChange}
                placeholder="Password"
              />
            </Form.Group>
            <Button variant="primary" type="submit">
              Submit
            </Button>
          </Form>
        </FalconComponentCard.Body>
      </FalconComponentCard>
    </>
  );
};

export default FormLayout;
