import paths from './paths';

export const appRoutes = {
  label: 'app',
  children: [
    {
      name: 'Doctor Special',
      icon: 'calendar-alt',
      to: paths.getDoctorSpecial,
      active: true
    },
    {
      name: 'Doctor',
      icon: 'calendar-alt',
      to: paths.getDoctorList,
      active: true
    },
    {
      name: 'Drug-Category',
      icon: 'calendar-alt',
      to: paths.drugCategory,
      active: true
    },
    {
      name: 'Drug',
      icon: 'calendar-alt',
      to: paths.drugList,
      active: true
    },
    {
      name: 'Medi-Rep',
      icon: 'calendar-alt',
      to: paths.docList,
      active: true
    },
    {
      name: 'User',
      icon: 'user',
      active: true,
      children: [
        {
          name: 'Users-List',
          to: paths.userList,
          active: true
        },
        {
          name: 'Users-Role',
          to: paths.userRole,
          active: true
        }
      ]
    }
  ]
};

export default [appRoutes];
