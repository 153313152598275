import React, { useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import PageHeader from 'components/common/PageHeader';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import FalconComponentCard from 'components/common/FalconComponentCard';
// import { reactBootstrapDocsUrl } from 'helpers/utils';
// import { DrugCategory } from './DrugCategory';
import { toast } from 'react-toastify';
import axios from 'axios';
import API_BASE_URL from 'apiConfig';
import { useNavigate } from 'react-router-dom';

// const basicFormCode = DrugCategory;

const FormLayout = () => {
  const navigate = useNavigate();

  const [name, setName] = useState('');

  const handleNameChange = event => {
    setName(event.target.value);
  };

  const handleSubmit = async e => {
    e.preventDefault();
    const token = localStorage.getItem('token');

    try {
      const response = await axios.post(
        `${API_BASE_URL}/drug/add-category`,
        {
          name: name
        },
        {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            Authorization: `Bearer ${token}`
          }
        }
      );

      if (!response) {
        toast.error('Drug category not added!', {
          theme: 'colored'
        });
      } else {
        setName('');
        navigate('/drug/drug-category', { replace: true });
        toast.success('Drug Category Added Succesfully!', {
          theme: 'colored'
        });
      }
    } catch (error) {
      toast.error(error, {
        theme: 'colored'
      });
    }
  };

  return (
    <>
      <PageHeader title="Add new category" className="mb-3"></PageHeader>

      <FalconComponentCard>
        {/* <FalconComponentCard.Header title="Basic form" light={false} /> */}
        {/* <FalconComponentCard.Body  language="jsx" /> */}
        <FalconComponentCard.Body language="jsx">
          <Form onSubmit={handleSubmit}>
            <Form.Group className="mb-3" controlId="formGroupName">
              <Form.Label>Drug Category Name</Form.Label>
              <Form.Control
                type="text"
                value={name}
                name="name"
                onChange={handleNameChange}
                placeholder="Drug Category Name"
              />
            </Form.Group>
            <Button variant="primary" type="submit">
              Submit
            </Button>
          </Form>
        </FalconComponentCard.Body>
      </FalconComponentCard>
    </>
  );
};

export default FormLayout;
