// import Divider from 'components/common/Divider';
// import { redirect } from 'react-router-dom';
// import { redirectDocument } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
// import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import axios from 'axios';
// import paths from 'routes/paths';
import API_BASE_URL from 'apiConfig'; // Import the base URL

// const forgotPasswordPaths = {
//   simple: paths.simpleForgotPassword,
//   split: paths.splitForgotPassword,
//   card: paths.cardForgotPassword
// };

const LoginForm = ({ hasLabel }) => {
  const navigate = useNavigate();

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const handleEmailChange = event => {
    setEmail(event.target.value);
  };

  const handlePasswordChange = event => {
    setPassword(event.target.value);
  };

  // Handler
  const handleSubmit = async e => {
    e.preventDefault();

    try {
      // Simulated API call to login endpoint
      const response = await axios.post(`${API_BASE_URL}/login`, {
        email: email,
        password: password
      });

      if (!response) {
        throw new Error('Login failed');
      } else {
        // Reset form fields and errors on successful login

        const token = response.data.token;
        localStorage.setItem('token', token);
        localStorage.getItem('token');

        const userPermission = response.data.userPermission;
        localStorage.setItem('userPermission', JSON.stringify(userPermission));

        const permission = JSON.parse(localStorage.getItem('userPermission'));

        console.log(permission);

        // alert(permission.includes(1));
        setEmail('');
        setPassword('');

        toast.success(`Logged in as ${email}`, {
          theme: 'colored'
        });

        navigate('/dashboard', { replace: true });
      }
    } catch (error) {
      // setError('Invalid username or password');
      // console.log(error);
      // const errs = error.response.data.errors;
      toast.success(`Invalid username or password`, {
        theme: 'colored'
      });
    }
  };

  return (
    <Form onSubmit={handleSubmit}>
      <Form.Group className="mb-3">
        {hasLabel && <Form.Label>Email address</Form.Label>}
        <Form.Control
          placeholder={!hasLabel ? 'Email address' : ''}
          value={email}
          name="email"
          onChange={handleEmailChange}
          type="email"
        />
      </Form.Group>

      <Form.Group className="mb-3">
        {hasLabel && <Form.Label>Password</Form.Label>}
        <Form.Control
          placeholder={!hasLabel ? 'Password' : ''}
          value={password}
          name="password"
          onChange={handlePasswordChange}
          type="password"
        />
      </Form.Group>

      <Row className="justify-content-between align-items-center">
        <Col xs="auto"></Col>
      </Row>

      <Form.Group>
        <Button
          type="submit"
          color="primary"
          className="mt-3 w-100"
          //   disabled={!formData.email || !formData.password}
        >
          Log in
        </Button>
      </Form.Group>
    </Form>
  );
};

LoginForm.propTypes = {
  layout: PropTypes.string,
  hasLabel: PropTypes.bool
};

LoginForm.defaultProps = {
  layout: 'simple',
  hasLabel: false
};

export default LoginForm;
